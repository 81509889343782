import React, { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { ArrowRight, Code, Database, Globe, Phone, Smartphone, MessageCircle, Shield, Headphones } from 'lucide-react';
import { Helmet } from 'react-helmet';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
}

interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export default function IcamobLandingPage() {
  return (
    <>
      <Helmet>
        <title>ICAMOB - Transforming Ideas into Digital Reality</title>
        <meta name="description" content="ICAMOB specializes in custom platforms, CRMs, software development, web and mobile app creation, cyber security, and IT consulting." />
        <meta name="keywords" content="ICAMOB, software development, web development, mobile apps, CRM, cyber security, IT consulting" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.icamob.ma" />
        <meta property="og:title" content="ICAMOB - Transforming Ideas into Digital Reality" />
        <meta property="og:description" content="ICAMOB specializes in custom platforms, CRMs, software development, web and mobile app creation, cyber security, and IT consulting." />
        <meta property="og:image" content="https://www.icamob.ma/og-image.jpg" />
        <meta property="og:url" content="https://www.icamob.ma" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "ICAMOB",
              "url": "https://www.icamob.ma",
              "logo": "https://www.icamob.ma/logo.png",
              "description": "ICAMOB specializes in custom platforms, CRMs, software development, web and mobile app creation, cyber security, and IT consulting.",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+212693934473",
                "contactType": "customer service"
              }
            }
          `}
        </script>
      </Helmet>
      <div className="min-h-screen bg-white text-black">
        <Header />
        <main>
          <Hero />
          <Services />
          <About />
          <CTA />
          <ContactManager />
        </main>
        <Footer />
      </div>
    </>
  );
}

function Header() {
  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <img src='/logo.png' alt="ICAMOB Logo" className='w-[130px]'/>
        <nav className="hidden md:flex space-x-6">
          <NavLink href="#services">Services</NavLink>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#contact">Contact</NavLink>
        </nav>
        <button className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition duration-300">
          Get Started
        </button>
      </div>
    </header>
  );
}

function NavLink({ href, children }: NavLinkProps) {
  return (
    <a href={href} className="text-black hover:text-red-600 transition duration-300">
      {children}
    </a>
  );
}

function Hero() {
  return (
    <section className="pt-32 pb-20 bg-gradient-to-br from-red-600 to-red-800 text-white">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h1 className="text-5xl md:text-6xl font-bold mb-6">Transforming Ideas into Digital Reality</h1>
          <p className="text-xl md:text-2xl mb-8">
            Platforms, CRMs, Software, Websites, and Mobile Apps - We Build It All
          </p>
          <button className="bg-white text-red-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300">
            Explore Our Services
          </button>
        </motion.div>
      </div>
    </section>
  );
}

function Services() {
  return (
    <section id="services" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center">
          <ServiceCard
            icon={<Database className="w-12 h-12 text-red-600" />}
            title="Custom Platforms & CRMs"
            description="Tailored solutions to streamline your business processes and manage customer relationships effectively."
          />
          <ServiceCard
            icon={<Code className="w-12 h-12 text-red-600" />}
            title="Software Development"
            description="Robust and scalable software solutions designed to meet your unique business needs."
          />
          <ServiceCard
            icon={<Globe className="w-12 h-12 text-red-600" />}
            title="Web Development"
            description="Stunning, responsive websites that engage your audience and drive conversions."
          />
          <ServiceCard
            icon={<Smartphone className="w-12 h-12 text-red-600" />}
            title="Mobile App Development"
            description="Intuitive and powerful mobile applications for iOS and Android platforms."
          />
          <ServiceCard
            icon={<Shield className="w-12 h-12 text-red-600" />}
            title="Cyber Security"
            description="Comprehensive security solutions to protect your digital assets and ensure data integrity."
          />
          <ServiceCard
            icon={<Headphones className="w-12 h-12 text-red-600" />}
            title="Support & Consulting"
            description="Expert guidance and ongoing support to help you navigate the digital landscape and optimize your IT infrastructure."
          />
        </div>
      </div>
    </section>
  );
}

function ServiceCard({ icon, title, description }: ServiceCardProps) {
  const controls = useAnimation();
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      transition={{ duration: 0.5 }}
      className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
    >
      <div className="mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </motion.div>
  );
}

function About() {
  return (
    <section id="about" className="py-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="md:w-1/2 mb-8 md:mb-0"
          >
            <h2 className="text-4xl font-bold mb-4">About ICAMOB</h2>
            <p className="text-xl text-gray-600 mb-6">
              We are a team of passionate tech enthusiasts dedicated to bringing your digital visions to life. With
              expertise in cutting-edge technologies and a commitment to excellence, we deliver solutions that drive
              your business forward.
            </p>
            <ul className="space-y-2">
              {['Innovative Solutions', 'Expert Team', 'Cutting-edge Technology', 'Client-focused Approach'].map(
                (item, index) => (
                  <li key={index} className="flex items-center">
                    <ArrowRight className="w-5 h-5 text-red-600 mr-2" />
                    <span>{item}</span>
                  </li>
                )
              )}
            </ul>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="md:w-1/2"
          >
            <img
              src="/team.jpg"
              alt="ICAMOB Team"
              className="rounded-lg shadow-xl"
              width={600}
              height={400}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}

function CTA() {
  return (
    <section className="py-20 bg-black text-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold mb-4">Ready to Bring Your Ideas to Life?</h2>
        <p className="text-xl mb-8">Let's collaborate and create something amazing together.</p>
        <button className="bg-red-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300">
          Get in Touch
        </button>
      </div>
    </section>
  );
}

function ContactManager() {
  return (
    <section id="contact" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-white rounded-lg shadow-xl p-8 max-w-2xl mx-auto"
        >
          <h2 className="text-3xl font-bold mb-4 text-center">Speak with Our Client Relations Manager</h2>
          <div className="flex flex-col items-center">
            <img
              src="/issam.png"
              alt="BELABBES ISSAM"
              className="rounded-full w-32 h-32 object-cover mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">BELABBES ISSAM</h3>
            <p className="text-gray-600 mb-4">Chief Client Relations Manager</p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a
                href="tel:+212693934473"
                className="bg-red-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300 flex items-center justify-center"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call Now
              </a>
              <a
                href="https://wa.me/212693934473"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-green-700 transition duration-300 flex items-center justify-center"
              >
                <MessageCircle className="w-5 h-5 mr-2" />
                WhatsApp
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <img src='/logo.png' alt="ICAMOB Logo" className='w-[130px] bg-white shadow-sm rounded-sm' />
            <p className="mt-2">Transforming ideas into digital reality</p>
          </div>
          <nav className="flex space-x-4">
            <NavLink href="#services">Services</NavLink>
            <NavLink href="#about">About</NavLink>
            <NavLink href="#contact">Contact</NavLink>
          </nav>
        </div>
        <div className="mt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} ICAMOB. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}